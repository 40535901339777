<template>
  <b-modal
    id="banner-grade-modal"
    centered
    :title="modalTitle"
    cancel-disabled
    ok-disabled
    @hide="() => handleCancel('forceCancel')"
  >
    <template>
      <ul>
        <li>UC Order ID: {{ order.order }}</li>
        <li>First Name: {{ order.first_name }}</li>
        <li>Last Name: {{ order.last_name }}</li>
        <li>Course Number: {{ order.course ? order.course.course_number : ""}}</li>
        <li>Course Title: {{ order.course ? order.course.course_title : "" }}</li>
        <li>Credit(s): {{ order.course_credits }}</li>
        <li>Course Credit Fee: ${{ order.course_credit_fee }}</li>
        <li>Course Fixed Credit Fee: ${{ order.course_fixed_credit_fee }}</li>
        <li>Total Course Fee: ${{ totalCreditFee }}</li>
        <li>
          Course Completion Date: {{ formatDate(order.course_completion_date) }}
        </li>
        <li>Course Grade: {{ order.course_grade }}</li>
        <li>
          Grade Type:
          {{
            order.grade_type !== null && order.grade_type
              ? "UOP Banner"
              : "InsidePacific"
          }}
        </li>
        <li>UOP Student ID: {{ order.uop_student_id }}</li>
        <li v-if="$parent.checkIfAllowed(['uop_admin','admin'])">DOB: {{ formatDate(order.date_of_birth) }}</li>
        <li v-if="$parent.checkIfAllowed(['uop_admin', 'admin'])">Cashnet TX ID: {{ (order.cashnet_transactions && order.cashnet_transactions.length > 0) ? order.cashnet_transactions.map(e => e.id).join(', ') : "N/A" }}</li>
      </ul>
      <div class="font-weight-bold row p20">
        You are about to confirm that you have graded this course via Ellucian
        Banner at University of the Pacific, Benerd College. You should ONLY
        confirm and proceed after you have graded the course in Banner at UOP.
        The respective UOP Partners will be notified that you have entered the
        grades in Banner.
      </div>
    </template>
    <template #modal-footer="{ cancel /*hide, ok */ }">
      <b-button
        class="btn-red btn"
        variant="danger"
        @click="() => handleCancel(cancel)"
        :disabled="stage === 'processing'"
      >
        CANCEL
      </b-button>

      <b-button
        variant="success"
        @click="$emit('confirmBannerGrade')"
        :disabled="stage === 'processing'"
      >
        {{ next ? "CONFIRM & NEXT" : "CONFIRM" }}

        <b-spinner
          style="width: 20px; height: 20px"
          v-if="stage === 'processing'"
          label="Loading..."
        ></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    stage: {
      type: String,
      default: "",
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    modalMessage: {
      type: String,
      default: "",
    },
    next: {
      type: Object,
      default: null,
    },
    modalTitle: {
      type: String,
    },
  },
  computed: {
    totalCreditFee() {
      return (
        (this.order.course_credits ?? 0) * (this.order.course_credit_fee ?? 0) +
        (this.order.course_fixed_credit_fee ?? 0)
      );
    },
  },
  watch: {
    stage(newVal) {
      if (newVal === "success" || newVal === "error") {
        this.$emit("closeDialog");
        this.$root.$emit("bv::hide::modal", "banner-grade-modal");
      }
    },
  },
  methods: {
    handleCancel(action) {
      this.$emit("next", { action, type: "bannerGradesBulk" });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        timeZone: "America/Los_Angeles",
      });
    },
  },
};
</script>


<style scoped>
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.p20 {
  padding: 20px;
}
</style>